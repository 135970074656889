import { styled } from "@mui/material";
import { Layout } from "../Components/Layout";
import TableDates from "../Components/Zine/TableDates";
import { instaLinkClicked } from "../GoogleAnalytics/events";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";

import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const AboutText = styled("div")(() => ({
  maxWidth: "90vw",
  margin: "0 auto",
}));

const ColorRouletteSubmission = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout>
      <div>
        <AboutText>
          {renderText({
            key: "pages.color_roulette_submission.title",
          })}
          {renderText({
            key: "pages.color_roulette_submission.description",
          })}

          {renderText({
            key: "pages.color_roulette_submission.participation_description",
          })}
          {renderText({
            key: "pages.color_roulette_submission.guidelines_title",
          })}

          {renderText({
            url: "https://tally.so/r/w42Lqb",
            key: "pages.color_roulette_submission.guidelines_description",
          })}
          {renderText({
            key: "pages.color_roulette_submission.guidelines_example_title",
          })}
          {renderText({
            key: "pages.color_roulette_submission.guidelines_example_description",
          })}
          {renderText({
            key: "pages.color_roulette_submission.selection_description",
          })}
          <>
            {renderText({
              key: "pages.color_roulette_submission.dates",
            })}
            <TableDates />
          </>
          <>
            {renderText({ key: "pages.publications.contact" })}
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/collagequeendom"
                onClick={() => instaLinkClicked()}
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:collagequeendom@gmail.com"
              >
                <MailOutlineIcon />
              </a>
            </p>
          </>
        </AboutText>
        <br />
      </div>
    </Layout>
  );
};

export default ColorRouletteSubmission;
