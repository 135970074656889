import { Button, Divider, styled } from "@mui/material";
import { Layout } from "../Components/Layout";
import TableDates from "../Components/Zine/TableDates";
import { TallyPopup } from "../Components/TallyForm/TallyForm";
import { Grid } from "@material-ui/core";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Size } from "../utils/types";
import { useIsMobile } from "../helpers/isMobile";
import { instaLinkClicked, pageClick } from "../GoogleAnalytics/events";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";
import { ZinesCarousel } from "../Components/ZinesCarousel";
import { useNavigate } from "react-router-dom";

const Item = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ColorRoulette = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { renderText } = useTranslatedContent();
  const handleClick = (path: string) => {
    pageClick(path);
    navigate(path);
  };
  return (
    <Layout size={Size.SMALL}>
      {renderText({ key: "pages.publications.title" })}
      {renderText({
        key: "pages.publications.description",
      })}
      <ZinesCarousel />
      <>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} md={4}>
            <Item>
              {renderText({ key: "pages.publications.submitting_title" })}
              {renderText({ key: "pages.publications.participation_fee" })}
              <Button
                variant="contained"
                aria-label={renderText({
                  key: "pages.publications.policy_cta",
                }).toString()}
                size="small"
                onClick={() => handleClick("/zines/submission")}
                sx={{ margin: "8px " }}
              >
                {renderText({
                  key: "pages.publications.policy_cta",
                  url: "/zines/submission",
                })}
              </Button>
              <br />
              <Button
                variant="contained"
                aria-label={renderText({
                  key: "pages.publications.submission_cta",
                }).toString()}
                size="small"
                sx={{ margin: "8px " }}
                onClick={() =>
                  (window.location.href = "https://tally.so/r/w42Lqb")
                }
              >
                {renderText({
                  key: "pages.publications.submission_cta",
                  url: "https://tally.so/r/w42Lqb",
                })}
              </Button>
            </Item>
            <Divider sx={{ margin: "12px 0" }} />
            {renderText({ key: "pages.publications.contact" })}
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/collagequeendom"
                onClick={() => instaLinkClicked()}
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:collagequeendom@gmail.com"
              >
                <MailOutlineIcon />
              </a>
            </p>
          </Grid>
          <Grid item xs={12} md={8}>
            <Item>
              <TableDates />
            </Item>
          </Grid>
        </Grid>
      </>
      {!isMobile && (
        <TallyPopup
          tallyConfig={{
            formId: "w42Lqb",
            openOnLoad: false,
            buttonText: renderText({
              key: "pages.publications.start_submission",
            }),
            popup: {
              emoji: {
                text: "✂️",
                animation: "bounce",
              },
            },
          }}
        />
      )}
      <br />
    </Layout>
  );
};

export default ColorRoulette;
