import { styled } from "@mui/material/styles";
import CaraBadge from "../../assets/caralogo.png";
import InstagramBadge from "../../assets/instagram_badge.svg";
import {
  caraLinkClicked,
  instaLinkClicked,
} from "../../GoogleAnalytics/events";

const SocialMediaWrapper = styled("div")(() => ({
  listStyleType: "none",
  margin: "0 auto",
  padding: "0",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  alignItems: "center",
}));

const FooterIcon = styled("a")(() => ({
  cursor: "pointer",
  margin: "6px",
  maxHeight: "40px",
}));

const CustomIcon = styled("img")(() => ({
  maxHeight: "30px",
}));

const SocialMedia = () => {
  return (
    <SocialMediaWrapper>
      <FooterIcon href="http://www.cara.app/collagequeendom" target="_blank">
        <CustomIcon
          src={CaraBadge}
          alt="cara"
          onClick={() => caraLinkClicked()}
        />
      </FooterIcon>
      <FooterIcon
        href="http://www.instagram.com/collagequeendom"
        target="_blank"
      >
        <CustomIcon
          src={InstagramBadge}
          alt="instagram"
          onClick={() => instaLinkClicked()}
        />
      </FooterIcon>
    </SocialMediaWrapper>
  );
};

export default SocialMedia;
