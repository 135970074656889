import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SocialMedia from "../SocialMedia";
import { Link } from "@mui/material";
import { LanguageSwitcher } from "../LanguageSwitcher";
import { useTranslatedContent } from "../LanguageSwitcher/hook";

const FooterContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: "center",
  bottom: 0,
  maxWidth: "100%",
}));

const FooterItemWrapper = styled("ul")(({ theme }) => ({
  listStyleType: "none",
  margin: "0 auto",
  display: "flex",
  flexFlow: "column wrap",
  justifyContent: "center",
  alignItems: "center",
  height: "min-content",
  padding: theme.spacing(2),
}));

const FooterItem = styled("li")(() => ({
  padding: "6px",
}));

type FooterProps = {
  mini: boolean;
};

const Footer = ({ mini }: FooterProps) => {
  const { renderText } = useTranslatedContent();

  return (
    <FooterContainer>
      <Divider variant="middle" />
      <FooterItemWrapper>
        <FooterItem>
          <Typography variant="body2" color="text.secondary">
            © 2024, Collage Queendom |{" "}
            {renderText({
              key: "footer.made_by",
              url: "mailto:sensitivehearingsoprano@gmail.com",
            })}
          </Typography>
        </FooterItem>
        <FooterItem>
          <Typography variant="body2" color="text.secondary">
            <Link href="mailto:collagequeendom@gmail.com">
              collagequeendom@gmail.com
            </Link>
          </Typography>
        </FooterItem>
        <FooterItem>
          <LanguageSwitcher />
        </FooterItem>
        {!mini && (
          <FooterItem>
            <SocialMedia />
          </FooterItem>
        )}
      </FooterItemWrapper>
    </FooterContainer>
  );
};

export default Footer;
