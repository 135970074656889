import ScrollToTop from "react-scroll-to-top";
import ImageGallery from "../Components/ImageGallery";
import Chip from "@mui/material/Chip";
import { Layout } from "../Components/Layout";
import { Divider } from "@mui/material";
import { images2022, images2024, images2023 } from "../assets/images";
import { Size } from "../utils/types";
import { FeaturedGallery } from "../Components/FeaturedGallery";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Portfolio = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout size={Size.SMALL}>
      {renderText({
        key: "pages.portfolio.title",
      })}
      <br />
      <FeaturedGallery />
      <br />
      <Accordion sx={{ background: "none", boxShadow: "none" }}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="archive-content"
          id="archive-header"
        >
          {renderText({
            key: "pages.portfolio.archive",
          })}
        </AccordionSummary>
        <AccordionDetails>
          <Divider>
            <Chip label="2024" size="small" />
          </Divider>
          <br />
          <ImageGallery images={images2024} size={500} margin={40} />
          <br />
          <Divider>
            <Chip label="2023" size="small" />
          </Divider>
          <br />
          <ImageGallery images={images2023} size={500} margin={40} />
          <br />
          <Divider>
            <Chip label="2022" size="small" />
          </Divider>
          <br />
          <ImageGallery images={images2022} size={200} margin={20} />
          <br />
        </AccordionDetails>
      </Accordion>
      <ScrollToTop smooth />
    </Layout>
  );
};

export default Portfolio;
