import { Button, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)(() => ({
  fontSize: "6pt",
}));

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      {i18n.language === "en" ? (
        <StyledButton variant="outlined" onClick={() => changeLanguage("es")}>
          Cambiar a Español
        </StyledButton>
      ) : (
        <StyledButton variant="outlined" onClick={() => changeLanguage("en")}>
          Change to English
        </StyledButton>
      )}
    </div>
  );
};
