import { Size } from "../../utils/types";

export const commonMargin = {
  full: "30px",
  half: "10px",
};

export const getSize = (size: Size) => {
  switch (size) {
    case Size.SMALL:
      return "30%";
    case Size.MID:
      return "45%";
    case Size.FULL:
    default:
      return "90%";
  }
};

export const getFontSize = (size: Size) => {
  switch (size) {
    case Size.SMALL:
      return "8pt";
    case Size.FULL:
      return "11pt";
    case Size.MID:
    default:
      return "9pt";
  }
};
