import { Button, styled, Typography } from "@mui/material";
import { Grid } from "@material-ui/core";
import { Layout } from "../Components/Layout";
import { useNavigate } from "react-router-dom";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";
import { Size } from "../utils/types";
import { Flipbook } from "../Components/Flipbook";
import { pageClick } from "../GoogleAnalytics/events";

const Item = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const YellowIssue = () => {
  const navigate = useNavigate();
  const { renderText } = useTranslatedContent();

  const handleClick = (to: string, internal: boolean) => {
    pageClick(to);

    if (internal) {
      navigate(to);
    } else {
      window.location.href = to;
    }
  };

  return (
    <Layout size={Size.MID}>
      <div>
        {" "}
        {renderText({
          key: "pages.publications.yellow_issue.title",
        })}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              {" "}
              {renderText({
                key: "pages.publications.yellow_issue.subtitle",
              })}
              <Typography variant="body1" color="text.secondary" mt={2} mb={2}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.argstudios.com"
                >
                  Anthony Grant
                </a>
                {", "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://unoesdimasiado.wixsite.com/dimasmelfi"
                >
                  Dimas Melfi
                </a>
                {", "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.feransis.com"
                >
                  Feransis
                </a>
                {", "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/papersneeze"
                >
                  Leslie Datsis
                </a>
                {", "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.marenbabarin.com"
                >
                  Maren Wiese
                </a>
                {", "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/stolenpainting"
                >
                  Robyn Redish{" "}
                </a>
                {renderText({
                  key: "common.and",
                })}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.thomasschneider-art.de"
                >
                  Thomas Schneider
                </a>{" "}
              </Typography>
              {renderText({
                key: "pages.publications.yellow_issue.description",
                url: "https://www.collagequeendom.com",
              })}
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <Flipbook pdfFile="/assets/pdf/yellow-issue-1.pdf" />
              <Button
                variant="contained"
                aria-label={renderText({
                  key: "pages.publications.yellow_issue.cta_button1",
                }).toString()}
                size="small"
                onClick={() => navigate("/contact")}
              >
                {renderText({
                  key: "pages.publications.yellow_issue.cta_button1",
                })}
              </Button>
              <br />
              <br />
              <Button
                variant="outlined"
                aria-label={renderText({
                  key: "pages.publications.yellow_issue.cta_button2",
                }).toString()}
                size="small"
                onClick={() =>
                  handleClick(
                    "https://collagequeendom.com/assets/pdf/yellow-issue-1.pdf",
                    false
                  )
                }
              >
                {renderText({
                  key: "pages.publications.yellow_issue.cta_button2",
                })}
              </Button>
            </Item>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export default YellowIssue;
