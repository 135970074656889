import { MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubMenuItem, SubmenuItem } from "./SubmenuItem";
import { styled } from "@mui/system";
import { LinkItem, links } from "./items";
import { pageClick } from "../../GoogleAnalytics/events";

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  ":hover": {
    color: "white",
  },
}));

export const NestedMenu = () => {
  const navigate = useNavigate();

  const [activeSubmenu, setActiveSubmenu] = useState<null | SubmenuItem[]>(
    null
  );

  const handleOnClick = (item: LinkItem) => {
    const { subMenuItems, to } = item;
    if (subMenuItems) {
      return () =>
        activeSubmenu ? setActiveSubmenu(null) : setActiveSubmenu(subMenuItems);
    } else {
      pageClick(to);
      return () => navigate(to);
    }
  };

  return (
    <div>
      {links.map((item: LinkItem, index: number) => {
        return (
          <div key={index}>
            <MenuItemStyled onClick={handleOnClick(item)}>
              {item.title}
            </MenuItemStyled>
            {item.subMenuItems === activeSubmenu &&
              activeSubmenu?.map((item, index) => (
                <SubMenuItem item={item} key={index} />
              ))}
          </div>
        );
      })}
    </div>
  );
};
