import ScrollToTop from "react-scroll-to-top";
import { Layout } from "../Components/Layout";
import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Size } from "../utils/types";
import { pageClick } from "../GoogleAnalytics/events";
import { useTranslation } from "react-i18next";

const getRandomFontSize = () =>
  `${Math.floor(Math.random() * (30 - 18 + 1) + 24)}pt`;

const CardTitle = styled(Typography)<{ size: string }>(({ theme, size }) => ({
  fontFamily: "Font",
  color: "white",
  fontSize: size,
  margin: "2px",
  backgroundColor: "#dc493a",
  padding: "8px",
  display: "inline-block",
  alignContent: "center",
  height: Number(size) * 2,
}));

const CardContainer = styled("div")`
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0;
  gap: 10px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    > * {
      max-height: 200px;
      overflow: hidden;
    }
  }
`;

const CardOption = styled("div")<{ background: string }>(({ background }) => ({
  position: "relative",
  backgroundImage: `url(${background})`,
  height: "600px",
  overflow: "hidden",
  borderRadius: "5px",
  display: "flex",
  flexFlow: "row wrap",
  justifyContent: "center",
  alignContent: "center",
  "::before": {
    backgroundRepeat: "no-repeat",
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    transition: "filter 0.3s ease",
    backgroundPosition: "top",
  },
  ":hover::before": {
    filter: "blur(5px)",
  },
  "> *": {
    position: "relative",
    zIndex: 2,
  },
}));

const Title = styled(Typography)`
  font-family: "Font";
  /* font-size: 120pt; */
  letter-spacing: 0.04em;
  font-size: clamp(2rem, 9vw, 9rem); /* Adjust the clamp values as needed */
  text-wrap: nowrap;
  color: #dc493a;
`;

const CustomDivider = styled(Divider)`
  max-width: 100%;
  width: 85vw;
  margin: 0 auto;
  border-color: #dc493a;
`;

const Home = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = (to: string) => {
    pageClick(to);
    navigate(to);
  };

  const headers = {
    art: i18n.language === "es" ? ["A", "r", "t", "e"] : ["A", "r", "t"],
    zines: ["Z", "i", "n", "e", "s"],
    exhibits:
      i18n.language === "es"
        ? ["M", "u", "e", "s", "t", "r", "a", "s"]
        : ["E", "x", "h", "i", "b", "i", "t", "s"],
  };

  return (
    <Layout size={Size.MID}>
      <Title variant="h1">Collage Queendom</Title>
      <CustomDivider variant="middle" />
      <CardContainer>
        <CardOption
          onClick={() => handleOnClick("/portfolio")}
          background="/assets/artoption.jpg"
        >
          <div>
            {headers.art.map((item: string, index: number) => (
              <CardTitle key={index} size={getRandomFontSize()}>
                {item}
              </CardTitle>
            ))}
          </div>
        </CardOption>
        <CardOption
          onClick={() => handleOnClick("/zines")}
          background="/assets/zineoption.jpg"
        >
          <div>
            {headers.zines.map((item: string, index: number) => (
              <CardTitle key={index} size={getRandomFontSize()}>
                {item}
              </CardTitle>
            ))}
          </div>
        </CardOption>
        <CardOption
          onClick={() => handleOnClick("/exhibitions")}
          background="/assets/artworldoption.jpg"
        >
          <div>
            {headers.exhibits.map((item: string, index: number) => (
              <CardTitle key={index} size={getRandomFontSize()}>
                {item}
              </CardTitle>
            ))}
          </div>
        </CardOption>
      </CardContainer>
      <ScrollToTop smooth />
    </Layout>
  );
};

export default Home;
