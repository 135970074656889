import { useEffect, useState } from "react"; 
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";

export const IframeTally = styled("iframe")(() => ({
  "& > .tally-powered": {
    marginRight: "2000px !important "
  }
}));

export const ContactForm = () => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const { i18n } = useTranslation();

  useEffect(() => {
    var script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    document.head.appendChild(script);
    setHasLoaded(true) 
  }, []);

  return (
    <div> 
      {hasLoaded ? <IframeTally
        data-tally-src="https://tally.so/embed/nGrB6O?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="228"
        frameBorder={0}
        lang={i18n.language}
        marginHeight={0}
        marginWidth={0}
        title="Contact form"
      ></IframeTally> :
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      }
    </div>
  );
};
