import { Size } from "../utils/types";
import { Layout } from "../Components/Layout";
import { Box } from "@material-ui/core";
import { Container, Divider } from "../Components/LayoutHelpers/helpers";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";

const Landing = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout size={Size.FULL}>
      <Container flexFlow="column wrap">
        <Box sx={{ textAlign: "left" }}>
          {renderText({ key: "pages.cookies.analytics" })}
        </Box>
        <Divider margin="32px 0 12px 0" />
        <Box sx={{ textAlign: "left" }}>
          {renderText({ key: "pages.cookies.privacy_policy" })}
        </Box>
      </Container>
    </Layout>
  );
};

export default Landing;
