import { styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import { useTranslatedContent } from "../LanguageSwitcher/hook";

const TableCellInactive = styled(TableCell)(({ theme }) => ({
  color: "#1f1f1f66",
}));

const TableWrapper = styled("div")(({ theme }) => ({
  textTransform: "capitalize",
}));

const TableDates = () => {
  const { renderText } = useTranslatedContent();

  return (
    <TableWrapper>
      {renderText({
        key: "pages.publications.table_dates.title",
      })}
      <br />
      <TableContainer>
        <Table size="small" aria-label="important dates">
          <TableHead>
            <TableRow>
              <TableCell>
                {renderText({
                  key: "pages.publications.table_dates.issue",
                })}
              </TableCell>
              <TableCell>
                {renderText({
                  key: "pages.publications.table_dates.deadline",
                })}
              </TableCell>
              <TableCell>
                {renderText({
                  key: "pages.publications.table_dates.selection_notification",
                })}
              </TableCell>
              <TableCell>
                {renderText({
                  key: "pages.publications.table_dates.publication_date",
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellInactive>
                {renderText({
                  key: "common.colors.green",
                })}
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.april",
                })}{" "}
                30
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.may",
                })}{" "}
                14
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.june",
                })}{" "}
                2024
              </TableCellInactive>
            </TableRow>
            <TableRow>
              <TableCellInactive>
                {renderText({
                  key: "common.colors.yellow",
                })}
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.june",
                })}{" "}
                2
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.june",
                })}{" "}
                15
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.july",
                })}{" "}
                2024
              </TableCellInactive>
            </TableRow>
            <TableRow>
              <TableCellInactive>
                {renderText({
                  key: "common.colors.orange",
                })}
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.july",
                })}{" "}
                6
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.july",
                })}{" "}
                15
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.august",
                })}{" "}
                2024
              </TableCellInactive>
            </TableRow>
            <TableRow>
              <TableCellInactive>
                {renderText({
                  key: "common.colors.red",
                })}
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.july",
                })}{" "}
                31
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.august",
                })}{" "}
                14
              </TableCellInactive>
              <TableCellInactive>
                {renderText({
                  key: "common.months.september",
                })}{" "}
                2024
              </TableCellInactive>
            </TableRow>
            <TableRow>
              <TableCell>
                {renderText({
                  key: "common.colors.purple",
                })}
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.august",
                })}{" "}
                31
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.september",
                })}{" "}
                16
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.october",
                })}{" "}
                2024
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {renderText({
                  key: "common.colors.blue",
                })}
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.september",
                })}{" "}
                30
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.october",
                })}{" "}
                14
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.november",
                })}{" "}
                2024
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                {renderText({
                  key: "common.colors.black_white",
                })}
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.october",
                })}{" "}
                31
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.november",
                })}{" "}
                18
              </TableCell>
              <TableCell>
                {renderText({
                  key: "common.months.december",
                })}{" "}
                2024
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default TableDates;
