export const images = [
  {
    original: {
      size: [35, 21],
    },
    img_src: "/assets/decoration/mouth.jpg",
    title: "mouth",
  },
  {
    original: {
      size: [21, 31],
    },
    img_src: "/assets/decoration/secretgarden.jpg",
    title: "secret garden",
  },
  {
    original: {
      size: [33, 25],
    },
    img_src: "/assets/decoration/twos.jpg",
    title: "Collection",
  },
  
  {
    original: {
      size: [34, 21],
    },
    img_src: "/assets/decoration/jumping.jpg",
    title: "jumping",
  },
  {
    original: {
      size: [33, 21],
    },
    img_src: "/assets/decoration/threes.jpg",
    title: "Collection",
  },
  {
    original: {
      size: [21, 31],
    },
    img_src: "/assets/decoration/the-game.jpg",
    title: "the game",
  },
  {
    original: {
      size: [28, 33],
    },
    img_src: "/assets/decoration/art.jpg",
    title: "Art",
  },
  
  {
    original: {
      size: [28, 21],
    },
    img_src: "/assets/decoration/comefindme.jpg",
    title: "Come find me",
  },
  {
    original: {
      size: [33, 21],
    },
    img_src: "/assets/decoration/atcs.jpg",
    title: "ATCs",
  },
  {
    original: {
      size: [28, 21],
    },
    img_src: "/assets/decoration/building.jpg",
    title: "Building",
  },
  {
    original: {
      size: [25, 25],
    },
    img_src: "/assets/decoration/collection.jpg",
    title: "Collection",
  },
  {
    original: {
      size: [30, 21],
    },
    img_src: "/assets/decoration/moonscape.jpg",
    title: "Moonscape",
  },
];
