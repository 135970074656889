import { Typography } from "@mui/material";
import { Layout } from "../Components/Layout";

const NotFound = () => {
  return (
    <Layout>
      <div>
        <Typography variant="h1" color="text.primary">
          Page Not Found.
        </Typography>
      </div>
    </Layout>
  );
};

export default NotFound;
