import { useTranslation, Trans } from "react-i18next";
import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export type TranslationProps = {
  key: string;
  url?: string;
};

const StyledA = styled("a")(() => ({
  margin: "0 4px",
}));

export const useTranslatedContent = () => {
  const { t } = useTranslation();

  const renderText = ({ key, url }: TranslationProps) => {
    const components: { [key: string]: JSX.Element } = {
      h1: <Typography variant="h1" />,
      h2: <Typography variant="h2" />,
      h3: <Typography variant="h3" />,
      h4: <Typography variant="h4" />,
      h5: <Typography variant="h5" />,
      h6: <Typography variant="h6" />,
      br: <br />,
      li: <li />,
      ol: <ol />,
      ul: <ul />,
      b: <b />,
      s: <s />,
      link: <Link to={url || ""} />,
      body1: <Typography variant="body1" />,
      body2: <Typography variant="body2" />,
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      a: (
        <StyledA href={url || "#"} target="_blank" rel="noopener noreferrer" />
      ),
    };

    return <Trans i18nKey={key} components={components} />;
  };

  const renderParagraphs = (key: string) => {
    const text = t(key);
    return text.split("\n").map((str) => renderText({ key: str }));
  };

  return { renderParagraphs, renderText };
};
