import { Document, Page, pdfjs } from "react-pdf";
import { Box, styled } from "@mui/material";
import { useState } from "react";
import HTMLFlipBook from "react-pageflip";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentWrapper = styled(Document)(({ theme }) => ({
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  "&.flipbook": {
    margin: "12px",
  },
}));

const PageContainer = styled(Box)(({ theme }) => ({}));

export const Flipbook = ({ pdfFile }: { pdfFile: string }) => {
  const [numPages, setNumPages] = useState<null | number>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const pagesList = () => {
    if (!numPages) {
      return null;
    }

    var pages = [];
    for (var i = 1; i <= numPages; i++) {
      pages.push(
        <PageContainer key={i} className="page">
          <Page
            width={352}
            pageNumber={i}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </PageContainer>
      );
    }
    return pages;
  };

  return (
    <DocumentWrapper
      file={pdfFile}
      onLoadSuccess={onDocumentLoadSuccess}
      className="flipbook"
    >
      <HTMLFlipBook
        width={352}
        height={500}
        startPage={0}
        size={"fixed"}
        minWidth={0}
        maxWidth={0}
        minHeight={0}
        maxHeight={0}
        drawShadow={true}
        flippingTime={1}
        usePortrait={true}
        startZIndex={0}
        autoSize={true}
        maxShadowOpacity={1}
        showCover={true}
        mobileScrollSupport={true}
        clickEventForward={false}
        useMouseEvents={true}
        swipeDistance={10}
        showPageCorners={true}
        disableFlipByClick={false}
        className={""}
        style={{}}
      >
        {pagesList()}
      </HTMLFlipBook>
    </DocumentWrapper>
  );
};
