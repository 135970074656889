import React, { useEffect, useState } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CookieOutlined from "@mui/icons-material/CookieOutlined";
import { Button, styled } from "@mui/material";
import { GATrackingId, consentId } from "../../helpers/googleAnalytics";
import { pageVisit } from "../../GoogleAnalytics/events";

const CookieButton = styled(Button)(() => ({
  textDecoration: "underline",
  position: "fixed",
  bottom: "10px",
  right: "10px",
  color: "#fff",
  border: "none",
  padding: "4px",
  cursor: "pointer",
  minWidth: "fit-content",
}));

export const ConsentBanner: React.FC = () => {
  const [hasConsented, setHasConsented] = useState(false);

  useEffect(() => {
    const consent = Cookies.get(consentId);
    if (consent === "true") {
      initializeGA();
      setHasConsented(true);
    }
  }, []);

  const initializeGA = () => {
    window.gtag("config", GATrackingId);
  };

  const handleAccept = () => {
    Cookies.set(consentId, "true", { expires: 365 });
    initializeGA();
    pageVisit(window.location.pathname + window.location.search);
    setHasConsented(true);
  };

  const handleDecline = () => {
    Cookies.set(consentId, "false", { expires: 365 });
    setHasConsented(false);
  };

  const handleOptOut = () => {
    Cookies.remove(consentId);
    setHasConsented(false);
    window.location.reload();
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        cookieName={consentId}
        style={{
          background: "#00000022",
          color: "#000",
          fontFamily: "Inter",
          fontSize: "8pt",
        }}
        buttonStyle={{
          color: "#fff",
          background: "#426A5A",
          fontSize: "13px",
        }}
        declineButtonStyle={{
          color: "#fff",
          background: "#BA324F",
          fontSize: "13px",
        }}
        expires={365}
        onAccept={handleAccept}
        onDecline={handleDecline}
      >
        We use cookies to enhance your experience and analyze our traffic. By
        clicking "Accept", you consent to the use of cookies. For more
        information, read our <a href="/privacypolicy">Cookie Policy</a>.
      </CookieConsent>
      {hasConsented && (
        <CookieButton variant="outlined" onClick={handleOptOut}>
          <CookieOutlined
            sx={{ width: "20px", height: "20px", color: "#BA324F" }}
          />
        </CookieButton>
      )}
      ;
    </>
  );
};
