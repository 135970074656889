import { styled } from "@mui/material/styles";
import { Size } from "../../utils/types";
import { featuredImages } from "../../assets/images";
import { LayoutContainer } from "../Layout";
import { commonMargin, getSize } from "./utils";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexFlow: "row wrap",
  maxWidth: "80vw",
  alignContent: "center",
  margin: "0 auto",
  justifyContent: "center",
  gap: "0 24px",
  "@media (max-width: 768px)": {
    flexFlow: "column wrap",
    margin: `${commonMargin.half} auto`,
  },
}));

const CustomImage = styled("img")<{ size: Size }>(({ theme, size }) => ({
  width: getSize(size),
  maxHeight: "90vh",
  maxWidth: "fit-content",
  justifySelf: "center",
  alignSelf: "center",
  flex: size === Size.FULL ? "1 1 100%" : "1 1 40%",
  margin:
    size === Size.FULL
      ? `${commonMargin.half} auto`
      : `${commonMargin.full} auto`,
  "@media (max-width: 768px)": {
    margin: `${commonMargin.half} auto`,
    maxWidth: getSize(Size.FULL),
    width: "100%",
  },
}));

const fullWidth = [4, 8, 10, 11];

export const FeaturedGallery = () => {
  return (
    <LayoutContainer>
      <Container>
        {featuredImages.map((image, index) => {
          const getSize = (id: number) => {
            if (id === 7) {
              return Size.SMALL;
            }
            return fullWidth.includes(image.id) ? Size.FULL : Size.MID;
          };

          return (
            <CustomImage
              key={index}
              src={image.img_src}
              size={getSize(image.id)}
            />
          );
        })}
      </Container>
    </LayoutContainer>
  );
};
