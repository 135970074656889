import { Divider, Link, styled, Typography } from "@mui/material";
import { images } from "../assets/prints";
import { Layout } from "../Components/Layout";
import ImageGallery from "../Components/ImageGallery";
import { useNavigate } from "react-router-dom";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";

const Text = styled("div")(() => ({
  maxWidth: "40vw",
  margin: "0 auto",
}));

const Prints = () => {
  const navigate = useNavigate();
  const { renderText } = useTranslatedContent();

  return (
    <Layout>
      <div>
        <Text>
          {renderText({ key: "pages.prints.title" })}
          {renderText({ key: "pages.prints.subtitle" })}

          <Typography variant="body1">
            <br />
            <Link onClick={() => navigate("/contact")}>
              {renderText({ key: "common.contact.get_in_touch" })}
            </Link>
          </Typography>
          {renderText({ key: "pages.prints.description" })}
          <br />
          <Divider />
          <br />
          <ImageGallery images={images} size={400} />
        </Text>
      </div>
    </Layout>
  );
};

export default Prints;
