import { Divider as DividerMUI } from "@material-ui/core";
import { styled } from "@mui/system";

export const Container = styled("div")<{ flexFlow?: string }>(
  ({ theme, flexFlow }) => ({
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    flexFlow: flexFlow || "row nowrap",
    maxWidth: "60vw",
    margin: "0 auto",
    "@media (max-width: 768px)": {
      maxWidth: "90vw",
      flexFlow: "column-reverse wrap",
    },
  })
);

export const Divider = styled(DividerMUI)<{ margin?: string }>(
  ({ theme, margin }) => ({
    margin: margin || "12px",
  })
);
