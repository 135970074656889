import { Carousel } from "../Carousel";
import { zinesList } from "./constants";
import { ZineCarouselItem } from "./ZineCarouselItem";

export const ZinesCarousel = () => {
  const normalizeZinesItems = zinesList.map((item, index) => (
    <ZineCarouselItem {...item} key={index} />
  ));

  return <Carousel items={normalizeZinesItems} />;
};
