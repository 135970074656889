import { styled, Typography } from "@mui/material";
import { Size } from "../utils/types";
import { Layout } from "../Components/Layout";
import { Container } from "../Components/LayoutHelpers/helpers";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";

const AboutText = styled("div")(() => ({
  alignSelf: " center",
  maxWidth: "40vw",
  margin: "32px auto",
  "@media (max-width: 768px)": {
    margin: "0 auto",
    maxWidth: "90vw",
  },
}));

// const AboutPic = styled("img")(() => ({
//   maxWidth: "300px",
//   maxHeight: "100%",
//   height: "100%",
//   alignSelf: " center",
//   margin: "32px",
//   "@media (max-width: 768px)": {
//     maxWidth: "70%",
//     width: "100%",
//     height: "auto",
//     margin: "0 auto",
//   },
// }));

const About = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout size={Size.SMALL}>
      <Typography variant="h1">
        {renderText({ key: "pages.about.title" })}
      </Typography>
      <Container>
        <AboutText>
          {renderText({ key: "pages.about.intro" })}
          {renderText({ key: "pages.about.bio" })}
        </AboutText>
        <br />
        {/* <CommissionsText>
          <Typography variant="body2" color="text.primary" mt={4}>
            Open for commissions. I'm especially interested in working with
            musicians to create album art.{" "}
            <Contact href="mailto:collagequeendom@gmail.com">
              Get in touch
            </Contact>
          </Typography>
        </CommissionsText> */}
        {/* <AboutText></AboutText> */}
        {/* <AboutPic src="/assets/biopic.jpg" alt="about saki" /> */}
      </Container>
    </Layout>
  );
};

export default About;
