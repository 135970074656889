export const featuredImages = [
{
  id: 4,
  img_src: "/assets/artwork/images/puzzle.png",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
{
  id: 5,
  img_src: "/assets/artwork/images/tvman.png",
  description: "This artwork was made in June 2024",
  title: "Untitled",
},
{
  id: 6,
  img_src: "/assets/artwork/images/trainman.png",
  description: "This artwork was made in June 2024",
  title: "Untitled",
},
{
  id: 9,
  img_src: "/assets/artwork/images/mouth.jpg",
  thumbnail_src: "/assets/artwork/thumbnails/mouth-thumbnail.jpg",
  description: "This artwork was made in June 2023",
  title: "The things we hold inside",
},
{
  id: 7,
  img_src: "/assets/artwork/images/red-line.png",
  description: "This artwork was made in May 2024",
  title: "Untitled",
},
{
  id: 8,
  img_src: "/assets/artwork/images/escultura.jpg",
  description: "This artwork was made in May 2024",
  title: "Untitled",
},
{
  id: 10,
  img_src: "/assets/artwork/images/history.jpg",
  thumbnail_src: "/assets/artwork/thumbnails/history-thumbnail.jpg",
  description: "This artwork was made in June 2023",
  title: "History is being made",
},
{
  id: 1,
  img_src: "/assets/artwork/images/family.png",
  description: "This artwork was made in February 2024",
  title: "Untitled",
},
{
  id: 3,
  img_src: "/assets/artwork/images/beauty.png",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
{
  id: 12,
  img_src: "/assets/artwork/images/unwrittenliterature.jpg",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
{
  id: 13,
  img_src: "/assets/artwork/images/desire.jpg",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
{
  id: 11,
  img_src: "/assets/artwork/images/mimicry.jpg",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
{
  id: 14,
  img_src: "/assets/artwork/images/oldschool.jpg",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
{
  id: 15,
  img_src: "/assets/artwork/images/oldschool2.jpg",
  description: "This artwork was made in July 2024",
  title: "Untitled",
},
]


export const images2024 = [
  { original: {
    available: true,
    size: [20, 20],
    img_src: "/assets/artwork/images/strawberries.png",
  },
  prints: {
    size: [
      [20, 30],
      [10, 15],
      [25, 35],
    ],
    img_src: "/assets/artwork/images/strawberries.png",
  },
  img_src: "/assets/artwork/images/strawberries.png",
  thumbnail_src: "/assets/artwork/thumbnails/strawberries-thumbnail.png",
  description: "This artwork was made in April 2024",
  title: "Strawberry Fields",
},
{
  original: {
    available: true,
    size: [25, 20],
    img_src: "/assets/artwork/images/minimal.png",
  },
  prints: {
    size: [
      [25,20],
    ],
    img_src: "/assets/artwork/images/minimal.png",
  },
  img_src: "/assets/artwork/images/minimal.png",
  thumbnail_src: "/assets/artwork/thumbnails/minimal-thumbnail.png",
  description: "This artwork was made in February 2024",
  title: "Untitled",
},
{
  original: {
    available: true,
    size: [25, 25],
    img_src: "/assets/artwork/images/satisfaction.jpg",
  },
  prints: {
    size: [
      [20, 30],
      [10, 15],
      [25, 35],
    ],
    img_src: "/assets/artwork/images/satisfaction.jpg",
  },
  img_src: "/assets/artwork/images/satisfaction.jpg",
  thumbnail_src: "/assets/artwork/thumbnails/satisfaction-thumbnail.jpg",
  description: "This artwork was made in April 2024",
  title: "Untitled",
},
{ original: {
available: true,
size: [20, 20],
img_src: "/assets/artwork/images/wheel.png",
},
prints: {
size: [
  [20, 30],
  [10, 15],
  [25, 35],
],
img_src: "/assets/artwork/images/wheel.png",
},
img_src: "/assets/artwork/images/wheel.png",
thumbnail_src: "/assets/artwork/thumbnails/wheel-thumbnail.png",
description: "This artwork was made in April 2024",
title: "The wheel",
},
{
  original: {
    available: true,
    size: [28, 25],
    img_src: "/assets/artwork/images/eyes.jpg",
  },
  prints: {
    size: [
      [20, 30],
      [10, 15],
      [25, 35],
    ],
    img_src: "/assets/artwork/images/eyes.jpg",
  },
  img_src: "/assets/artwork/images/eyes.jpg",
  thumbnail_src: "/assets/artwork/thumbnails/eyes-thumbnail.jpg",
  description: "This artwork was made in April 2024",
  title: "untitled",
},
{
    original: {
      available: true,
      size: [21, 27],
      img_src: "/assets/artwork/images/standout.png",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/standout.png",
    },
    img_src: "/assets/artwork/images/standout.png",
    thumbnail_src: "/assets/artwork/thumbnails/standout-thumbnail.png",
    description: "This artwork was made in April 2024",
    title: "Stand Out",
  },
  {
    original: {
      available: true,
      size: [24, 33],
      img_src: "/assets/artwork/images/card.jpg",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/card.jpg",
    },
    img_src: "/assets/artwork/images/card.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/card-thumbnail.jpg",
    description: "This artwork was made in March 2024",
    title: "Untitled",
  },
  { original: {
    available: true,
    size: [20, 28],
    img_src: "/assets/artwork/images/oneway.png",
  },
  prints: {
    size: [
      [20, 30],
      [10, 15],
      [25, 35],
    ],
    img_src: "/assets/artwork/images/oneway.png",
  },
  img_src: "/assets/artwork/images/oneway.png",
  thumbnail_src: "/assets/artwork/thumbnails/oneway-thumbnail.png",
  description: "This artwork was made in April 2024",
  title: "The way",
},
{
  original: {
    available: true,
    size: [22, 30],
    img_src: "/assets/artwork/images/elegance.png",
  },
  prints: {
    size: [
      [25,20],
    ],
    img_src: "/assets/artwork/images/elegance.png",
  },
  img_src: "/assets/artwork/images/elegance.png",
  thumbnail_src: "/assets/artwork/thumbnails/elegance-thumbnail.png",
  description: "This artwork was made in April 2024",
  title: "Untitled",
},
{
  original: {
    available: true,
    size: [26, 33],
    img_src: "/assets/artwork/images/womensday.png",
  },
  prints: {
    size: [
      [20, 30],
      [10, 15],
      [25, 35],
    ],
    img_src: "/assets/artwork/images/womensday.png",
  },
  img_src: "/assets/artwork/images/womensday.png",
  thumbnail_src: "/assets/artwork/thumbnails/womensday-thumbnail.png",
  description: "This artwork was made in March 2024",
  title: "Women's Day",
}, 
  {
    original: {
      available: true,
      size: [20, 13],
      img_src: "/assets/artwork/images/smile.jpg",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/smile.jpg",
    },
    img_src: "/assets/artwork/images/smile.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/smile-thumbnail.jpg",
    description: "This artwork was made in March 2024",
    title: "Untitled",
  },
{
  original: {
    available: true,
    size: [20, 20],
    img_src: "/assets/artwork/images/cat.png",
  },
  prints: {
    size: [
      [25,20],
    ],
    img_src: "/assets/artwork/images/cat.png",
  },
  img_src: "/assets/artwork/images/cat.png",
  thumbnail_src: "/assets/artwork/thumbnails/cat-thumbnail.png",
  description: "This artwork was made in February 2024",
  title: "Untitled",
},
]

export const images2023 = [
  {
    original: {
      available: true,
      size: [20, 30],
      img_src: "/assets/artwork/images/headaches.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/headaches.jpg",
    },
    img_src: "/assets/artwork/images/headaches.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/headaches-thumbnail.jpg",
    description: "This artwork was made in August 2023",
    title: "Nervous Disorders",
  },
  {
    original: {
      available: true,
      size: [20, 28],
      img_src: "/assets/artwork/images/artworld.png",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/artworld.png",
    },
    img_src: "/assets/artwork/images/artworld.png",
    thumbnail_src: "/assets/artwork/thumbnails/artworld-thumbnail.png",
    description: "This artwork was made in early 2023",
    title: "Art World",
  },
  {
    original: {
      available: true,
      size: [20, 28],
      img_src: "/assets/artwork/images/coffee.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/coffee.jpg",
    },
    img_src: "/assets/artwork/images/coffee.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/coffee-thumbnail.jpg",
    description: "This artwork was made in August 2023",
    title: "Espresso Dreams",
  },
  {
    original: {
      available: true,
      size: [20, 28],
      img_src: "/assets/artwork/images/deserve.png",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/deserve.png",
    },
    img_src: "/assets/artwork/images/deserve.png",
    thumbnail_src: "/assets/artwork/thumbnails/deserve-thumbnail.png",
    description: "This artwork was made in April 2024",
    title: "deserve",
  },
  {
    original: {
      available: true,
      size: [35, 42],
      img_src: "/assets/artwork/images/staircase.jpg",
    },
    prints: {
      size: [
        [35, 42],
      ],
      img_src: "/assets/artwork/images/staircase.jpg",
    },
    img_src: "/assets/artwork/images/staircase.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/staircase-thumbnail.jpg",
    description: "This artwork was made in November 2023",
    title: "Staircase",
  },
  {
    original: {
      available: true,
      size: [20, 15],
      img_src: "/assets/artwork/images/ernestina.png",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/ernestina.png",
    },
    img_src: "/assets/artwork/images/ernestina.png",
    thumbnail_src: "/assets/artwork/thumbnails/ernestina-thumbnail.png",
    description: "This artwork was made in November 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [13, 20],
      img_src: "/assets/artwork/images/flowersfordani.png",
    },
    prints: {
      size: [
        [13, 20],
      ],
      img_src: "/assets/artwork/images/flowersfordani.png",
    },
    img_src: "/assets/artwork/images/flowersfordani.png",
    thumbnail_src: "/assets/artwork/thumbnails/flowersfordani-thumbnail.png",
    description: "This artwork was made in November 2023",
    title: "Flowers for Dani",
  },
  {
    original: {
      available: true,
      size: [22, 20],
      img_src: "/assets/artwork/images/astronaut.png",
    },
    prints: {
      size: [
        [20, 20],
        [15, 15],
        [25, 25],
      ],
      img_src: "/assets/artwork/images/astronaut.png",
    },
    img_src: "/assets/artwork/images/astronaut.png",
    thumbnail_src: "/assets/artwork/thumbnails/astronaut-thumbnail.png",
    description: "This artwork was made in early 2023",
    title: "Lost in Space",
  },
  {
    original: {
      available: true,
      size: [20, 25],
      img_src: "/assets/artwork/images/thegame.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/thegame.jpg",
    },
    img_src: "/assets/artwork/images/thegame.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/thegame-thumbnail.jpg",
    description: "This artwork was made in early 2023",
    title: "The Game",
  },
  {
    original: {
      available: true,
      size: [15, 10],
      img_src: "/assets/artwork/images/walking.png",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/walking.png",
    },
    img_src: "/assets/artwork/images/walking.png",
    thumbnail_src: "/assets/artwork/thumbnails/walking-thumbnail.png",
    description: "This artwork was made in August 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [23, 32],
      img_src: "/assets/artwork/images/burningdownthehouse.png",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/burningdownthehouse.png",
    },
    img_src: "/assets/artwork/images/burningdownthehouse.png",
    thumbnail_src: "/assets/artwork/thumbnails/burningdownthehouse-thumbnail.png",
    description: "This artwork was made in 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [10, 12],
      img_src: "/assets/artwork/images/giantsonmars.jpg",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/giantsonmars.jpg",
    },
    img_src: "/assets/artwork/images/giantsonmars.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/giantsonmars-thumbnail.jpg",
    description: "This artwork was made in October 2023",
    title: "Untitled",
  },
  
  {
    original: {
      available: true,
      size: [6.5, 9.5],
      img_src: "/assets/artwork/images/dreams.jpg",
    },
    prints: {
      size: [
        [10, 13],
        [15, 17],
      ],
      img_src: "/assets/artwork/images/dreams.jpg",
    },
    img_src: "/assets/artwork/images/dreams.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/dreams-thumbnail.jpg",
    description: "This artwork was made in February 2023",
    title: "Dreams",
  },
  {
    original: {
      available: true,
      size: [8, 11],
      img_src: "/assets/artwork/images/birdie.png",
    },
    prints: {
      size: [
        [8, 11],
      ],
      img_src: "/assets/artwork/images/birdie.png",
    },
    img_src: "/assets/artwork/images/birdie.png",
    thumbnail_src: "/assets/artwork/thumbnails/birdie-thumbnail.png",
    description: "This artwork was made in November 2023",
    title: "Birdie",
  },
  {
    original: {
      available: false,
      size: [6.5, 9.5],
      img_src: "/assets/artwork/images/soul-and-energy.jpg",
    },
    prints: {
      size: [
        [10, 13],
        [15, 17],
      ],
      img_src: "/assets/artwork/images/soul-and-energy.jpg",
    },
    img_src: "/assets/artwork/images/soul-and-energy.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/soul-and-energy-thumbnail.jpg",
    description: "This artwork was made in February 2023",
    title: "Soul & Energy",
  },
  {
    original: {
      available: true,
      size: [7, 10],
      img_src: "/assets/artwork/images/boat.jpg",
    },
    prints: {
      size: [
        [7, 10],
      ],
      img_src: "/assets/artwork/images/boat.jpg",
    },
    img_src: "/assets/artwork/images/boat.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/boat-thumbnail.jpg",
    description: "This artwork was made early 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [20, 28],
      img_src: "/assets/artwork/images/alice.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/alice.jpg",
    },
    img_src: "/assets/artwork/images/alice.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/alice-thumbnail.jpg",
    description: "This artwork was made in May 2023",
    title: "Alice",
  },
  {
    original: {
      available: true,
      size: [6.5, 9.5],
      img_src: "/assets/artwork/images/fastforward.jpg",
    },
    prints: {
      size: [
        [10, 13],
        [15, 17],
      ],
      img_src: "/assets/artwork/images/fastforward.jpg",
    },
    img_src: "/assets/artwork/images/fastforward.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/fastforward-thumbnail.jpg",
    description: "This artwork was made in February 2023",
    title: "Fast Forward",
  },
  {
    original: {
      available: true,
      size: [20, 25],
      img_src: "/assets/artwork/images/smoke.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/smoke.jpg",
    },
    img_src: "/assets/artwork/images/smoke.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/smoke-thumbnail.jpg",
    description: "This artwork was made in early 2023",
    title: "Smoke",
  },
  {
    original: {
      available: true,
      size: [24, 27],
      img_src: "/assets/artwork/images/sailor.png",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/sailor.png",
    },
    img_src: "/assets/artwork/images/sailor.png",
    thumbnail_src: "/assets/artwork/thumbnails/sailor-thumbnail.png",
    description: "This artwork was made in December 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [20, 30],
      img_src: "/assets/artwork/images/couple.png",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/couple.png",
    },
    img_src: "/assets/artwork/images/couple.png",
    thumbnail_src: "/assets/artwork/thumbnails/couple-thumbnail.png",
    description: "This artwork was made in August 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [30, 21],
      img_src: "/assets/artwork/images/goodnews.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/goodnews.jpg",
    },
    img_src: "/assets/artwork/images/goodnews.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/goodnews-thumbnail.jpg",
    description: "This artwork was made in early 2023",
    title: "Good News",
  },
  
  
  {
    original: {
      available: true,
      size: [20, 20],
      img_src: "/assets/artwork/images/buddies.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/buddies.jpg",
    },
    img_src: "/assets/artwork/images/buddies.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/buddies-thumbnail.jpg",
    description: "This artwork was made in August 2023",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [20, 19],
      img_src: "/assets/artwork/images/flymetothemoon.jpg",
    },
    prints: {
      size: [
        [20, 19],
      ],
      img_src: "/assets/artwork/images/flymetothemoon.jpg",
    },
    img_src: "/assets/artwork/images/flymetothemoon.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/flymetothemoon-thumbnail.jpg",
    description: "This artwork was made in November 2023",
    title: "Untitled",
  },
]

export const images2022 = [
  {
    original: {
      available: false,
      size: [24, 20],
      img_src: "/assets/artwork/images/cars.png",
    },
    prints: {
      size: [
        [10, 13],
        [15, 17],
      ],
      img_src: "/assets/artwork/images/cars.png",
    },
    img_src: "/assets/artwork/images/cars.png",
    thumbnail_src: "/assets/artwork/thumbnails/cars-thumbnail.png",
    description: "This artwork was made in 2022",
    title: "Untitled",
  },
  
  {
    original: {
      available: true,
      size: [20, 30],
      img_src: "/assets/artwork/images/femaleidentity.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/femaleidentity.jpg",
    },
    img_src: "/assets/artwork/images/femaleidentity.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/femaleidentity-thumbnail.jpg",
    description: "This artwork was made in October 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [28, 20],
      img_src: "/assets/artwork/images/woman.jpg",
    },
    prints: {
      size: [
        [30, 20],
        [15, 10],
        [35, 25],
      ],
      img_src: "/assets/artwork/images/woman.jpg",
    },
    img_src: "/assets/artwork/images/woman.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/woman-thumbnail.jpg",
    description: "This artwork was made in November 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [30, 20],
      img_src: "/assets/artwork/images/pastwarfuturewar.jpg",
    },
    prints: {
      size: [
        [30, 20],
        [15, 10],
        [35, 25],
      ],
      img_src: "/assets/artwork/images/pastwarfuturewar.jpg",
    },
    img_src: "/assets/artwork/images/pastwarfuturewar.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/pastwarfuturewar-thumbnail.jpg",
    description: "This artwork was made in November 2022",
    title: "Look behind and take a look ahead",
  },
  {
    original: {
      available: true,
      size: [25, 30],
      img_src: "/assets/artwork/images/building.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/building.jpg",
    },
    img_src: "/assets/artwork/images/building.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/building-thumbnail.jpg",
    description: "This artwork was made in November 2022",
    title: "Warfare",
  },
  {
    original: {
      available: true,
      size: [30, 20],
      img_src: "/assets/artwork/images/paintbrush.jpg",
    },
    prints: {
      size: [
        [13, 18],
        [18, 24],
        [28, 35],
      ],
      img_src: "/assets/artwork/images/paintbrush.jpg",
    },
    img_src: "/assets/artwork/images/paintbrush.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/paintbrush-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "Untitle",
  },

  {
    original: {
      available: true,
      size: [20, 30],
      img_src: "/assets/artwork/images/fairies.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/fairies.jpg",
    },
    img_src: "/assets/artwork/images/fairies.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/fairies-thumbnail.jpg",
    description: "This artwork was made in November 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [30, 30],
      img_src: "/assets/artwork/images/visit.png",
    },
    prints: {
      size: [
        [15, 15],
        [20, 20],
        [30, 30],
      ],
      img_src: "/assets/artwork/images/visit.png",
    },
    img_src: "/assets/artwork/images/visit.png",
    thumbnail_src: "/assets/artwork/thumbnails/visit-thumbnail.png",
    description: "This artwork was made in October 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [20, 30],
      img_src: "/assets/artwork/images/secretgarden.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/secretgarden.jpg",
    },
    img_src: "/assets/artwork/images/secretgarden.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/secretgarden-thumbnail.jpg",
    description: "This artwork was made in October 2022",
    title: "Secret Garden",
  },
  {
    original: {
      available: true,
      size: [30, 20],
      img_src: "/assets/artwork/images/comefindme.jpg",
    },
    prints: {
      size: [
        [20, 15],
        [30, 24],
        [40, 30],
      ],
      img_src: "/assets/artwork/images/comefindme.jpg",
    },
    img_src: "/assets/artwork/images/comefindme.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/comefindme-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "Come find me",
  },
  {
    original: {
      available: true,
      size: [15, 15],
      img_src: "/assets/artwork/images/moonlandscape.jpg",
    },
    prints: {
      size: [
        [15, 15],
        [20, 20],
        [30, 30],
      ],
      img_src: "/assets/artwork/images/moonlandscape.jpg",
    },
    img_src: "/assets/artwork/images/moonlandscape.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/moonlandscape-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [10, 19],
      img_src: "/assets/artwork/images/nightofthedeer.jpg",
    },
    prints: {
      size: [
        [10, 19],
        [15, 28.5],
        [20, 38],
      ],
      img_src: "/assets/artwork/images/nightofthedeer.jpg",
    },
    img_src: "/assets/artwork/images/nightofthedeer.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/nightofthedeer-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "The Night of the Deer",
  },
  {
    original: {
      available: true,
      size: [20, 25],
      img_src: "/assets/artwork/images/marslandscape.jpg",
    },
    prints: {
      size: [
        [10, 15],
        [20, 25],
        [30, 40],
      ],
      img_src: "/assets/artwork/images/marslandscape.jpg",
    },
    img_src: "/assets/artwork/images/marslandscape.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/marslandscape-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [15, 15],
      img_src: "/assets/artwork/images/balletdancer.jpg",
    },
    prints: {
      size: [
        [15, 15],
        [20, 20],
        [30, 30],
      ],
      img_src: "/assets/artwork/images/balletdancer.jpg",
    },
    img_src: "/assets/artwork/images/balletdancer.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/balletdancer-thumbnail.jpg",
    description: "This artwork was made in August 2022",
    title: "Untitled",
  },
  {
    original: {
      available: false,
      size: [12, 10],
      img_src: "/assets/artwork/images/liberacion.jpg",
    },
    prints: {
      size: [
        [15, 10],
        [25, 20],
        [40, 30],
      ],
      img_src: "/assets/artwork/images/liberacion.jpg",
    },
    img_src: "/assets/artwork/images/liberacion.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/liberacion-thumbnail.jpg",
    description: "This artwork was made in August 2022",
    title: "Untitled",
  },
  {
    original: {
      available: false,
      size: [20, 25],
      img_src: "/assets/artwork/images/manandboy.jpg",
    },
    prints: {
      size: [
        [12, 16],
        [20, 25],
        [30, 40],
      ],
      img_src: "/assets/artwork/images/manandboy.jpg",
    },
    img_src: "/assets/artwork/images/manandboy.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/manandboy-thumbnail.jpg",
    description: "This artwork was made in July 2022",
    title: "Future Generations",
  },
  {
    original: {
      available: true,
      size: [10, 15],
      img_src: "/assets/artwork/images/flowers.jpg",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/flowers.jpg",
    },
    img_src: "/assets/artwork/images/flowers.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/flowers-thumbnail.jpg",
    description: "This artwork was made in December 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [20, 29],
      img_src: "/assets/artwork/images/photo.jpg",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/photo.jpg",
    },
    img_src: "/assets/artwork/images/photo.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/photo-thumbnail.jpg",
    description: "This artwork was made in 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [12, 16],
      img_src: "/assets/artwork/images/baby.png",
    },
    prints: {
      size: [
        [25,20],
      ],
      img_src: "/assets/artwork/images/baby.png",
    },
    img_src: "/assets/artwork/images/baby.png",
    thumbnail_src: "/assets/artwork/thumbnails/baby-thumbnail.png",
    description: "This artwork was made in December 2022",
    title: "Untitled",
  },
  {
    original: {
      available: false,
      size: [20, 30],
      img_src: "/assets/artwork/images/castleinthesky.jpg",
    },
    prints: {
      size: [
        [20, 30],
        [10, 15],
        [25, 35],
      ],
      img_src: "/assets/artwork/images/castleinthesky.jpg",
    },
    img_src: "/assets/artwork/images/castleinthesky.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/castleinthesky-thumbnail.jpg",
    description: "This artwork was made in October 2022",
    title: "Castle in the Sky",
  },
  {
    original: {
      available: false,
      size: [18, 25],
      img_src: "/assets/artwork/images/blue.jpg",
    },
    prints: {
      size: [
        [10, 15],
        [20, 25],
        [30, 40],
      ],
      img_src: "/assets/artwork/images/blue.jpg",
    },
    img_src: "/assets/artwork/images/blue.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/blue-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [18, 25],
      img_src: "/assets/artwork/images/jumpinwater.jpg",
    },
    prints: {
      size: [
        [10, 15],
        [20, 25],
        [30, 40],
      ],
      img_src: "/assets/artwork/images/jumpinwater.jpg",
    },
    img_src: "/assets/artwork/images/jumpinwater.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/jumpinwater-thumbnail.jpg",
    description: "This artwork was made in September 2022",
    title: "Untitled",
  },

  {
    original: {
      available: false,
      size: [7, 9],
      img_src: "/assets/artwork/images/shortcuttolove.jpg",
    },
    prints: {
      size: [[7, 9]],
      img_src: "/assets/artwork/images/shortcuttolove.jpg",
    },
    img_src: "/assets/artwork/images/shortcuttolove.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/shortcuttolove-thumbnail.jpg",
    description: "This artwork was made in December 2022",
    title: "Shortcut to love",
  },
  {
    original: {
      available: false,
      size: [9, 9],
      img_src: "/assets/artwork/images/boywithviolin.jpg",
    },
    prints: {
      size: [[9, 9]],
      img_src: "/assets/artwork/images/boywithviolin.jpg",
    },
    img_src: "/assets/artwork/images/boywithviolin.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/boywithviolin-thumbnail.jpg",
    description: "This artwork was made in December 2022",
    title: "Untitled",
  },
  {
    original: {
      available: false,
      size: [20, 20],
      img_src: "/assets/artwork/images/ladies.jpg",
    },
    prints: {
      size: [
        [30, 30],
        [10, 10],
        [25, 25],
      ],
      img_src: "/assets/artwork/images/ladies.jpg",
    },
    img_src: "/assets/artwork/images/ladies.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/ladies-thumbnail.jpg",
    description: "This artwork was made in December 2022",
    title: "Untitled",
  },
  {
    original: {
      available: true,
      size: [30, 30],
      img_src: "/assets/artwork/images/moon_goddess.jpg",
    },
    prints: {
      size: [
        [30, 30],
        [15, 15],
        [40, 40],
      ],
      img_src: "/assets/artwork/images/moon_goddess.jpg",
    },
    img_src: "/assets/artwork/images/moon_goddess.jpg",
    thumbnail_src: "/assets/artwork/thumbnails/moon_goddess-thumbnail.jpg",
    description: "This artwork was made in November 2022",
    title: "Untitled",
  },
]
 