import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TemporaryDrawer from "./Drawer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const NavBar = styled("div")<{ isScrolled: boolean }>(
  ({ theme, isScrolled }) => ({
    backgroundColor: theme.palette.background.default,
    textAlign: "center",
    display: "grid",
    width: "100%",
    gridTemplateColumns: "1fr 1fr",
    position: "fixed",
    justifyItems: "end",
    alignItems: "center",
    zIndex: 99,
    top: 0,
    transition: isScrolled ? "all 0.3s ease-in-out" : "none",
    boxShadow: isScrolled ? "0 2px 4px rgba(0,0,0,0.1)" : "none",
    "& > *": {
      padding: "12px",
    },
  })
);

const Title = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  justifySelf: "start",
  fontFamily: "Font",
  fontWeight: 800,
  textWrap: "nowrap",
  [theme.breakpoints.down(450)]: {
    fontSize: "1rem",
  },
}));

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <NavBar isScrolled={isScrolled}>
      <Title variant="h5" color="text.primary" onClick={() => navigate("/")}>
        Collage Queendom
      </Title>
      <TemporaryDrawer />
    </NavBar>
  );
};

export default Navbar;
