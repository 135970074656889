import * as React from "react";
import Drawer from "@mui/material/Drawer";
import MenuIcon from '@mui/icons-material/Menu';
import Footer from "../Footer";
import styled from "styled-components";
import { NestedMenu } from "../NestedMenu";
 
const Menu = styled(MenuIcon)`
  cursor: pointer;
`;

const StyledDrawerContent = styled.div`
  flex-flow: column nowrap;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      <Menu onClick={toggleDrawer("right", true)} />  
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        style={{justifyContent: "space-between"}}
      >
        <StyledDrawerContent>        
          <NestedMenu />
          <Footer mini={false} />
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
}
