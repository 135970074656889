import { Button, styled, Typography } from "@mui/material";
import { Size } from "../utils/types";
import { Layout } from "../Components/Layout";
import { Instagram, Web } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { instaLinkClicked, pageClick } from "../GoogleAnalytics/events";

const Container = styled("div")(() => ({
  minHeight: "50vh",
  display: "flex",
  justifyContent: "center",
  flexFlow: "column nowrap",
  gap: "10px",
  maxWidth: "90vw",
  margin: "0 auto",
  "@media (min-width: 768px)": {
    maxWidth: "20vw",
  },
}));

const Landing = () => {
  const navigate = useNavigate();
  const handleClick = (to: string, internal: boolean) => {
    pageClick(to);

    if (internal) {
      navigate(to);
    } else {
      window.location.href = to;
      instaLinkClicked();
    }
  };

  return (
    <Layout size={Size.SMALL}>
      <Typography variant="h1">Saki</Typography>
      <Container>
        <Button
          variant="contained"
          startIcon={<Instagram fontSize="small" />}
          aria-label="instagram"
          size="small"
          onClick={() =>
            handleClick("https://www.instagram.com/collagequeendom", false)
          }
        >
          Instagram
        </Button>
        <Button
          variant="contained"
          startIcon={<Web fontSize="small" />}
          aria-label="website"
          size="small"
          onClick={() => handleClick("/", true)}
        >
          Website
        </Button>
      </Container>
    </Layout>
  );
};

export default Landing;
