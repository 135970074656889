import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

interface ItemProps {
  background?: string;
  isClickable: Boolean;
}

const Item = styled("div")<ItemProps>(({ background, isClickable, theme }) => ({
  background: background
    ? `url(${background}) no-repeat center center`
    : `url(${"/assets/coming-soon.png"}) no-repeat center center`,
  backgroundSize: "contain",
  height: "300px",
  maxHeight: "300px",
  maxWidth: "212px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: "12px",
  alignItems: "center",
  cursor: isClickable ? "pointer" : "default",
  opacity: !background ? "50%" : "100%",
  "&:hover": {
    opacity: "100%",
    background: !isClickable && !background && theme.palette.secondary.main,
  },
}));

type ZineCarouselItemProps = {
  src?: string;
  title: string;
  path?: string;
};

export const ZineCarouselItem = ({
  src,
  title,
  path,
}: ZineCarouselItemProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <Item
        background={src}
        aria-label={title}
        onClick={handleClick}
        isClickable={Boolean(path)}
      >
        {!Boolean(path) && !src && (
          <Typography
            variant="body2"
            sx={{
              fontSize: "16px",
              opacity: "150%",
              color: "#000",
            }}
          >
            Coming Soon: {title}
          </Typography>
        )}
      </Item>
    </>
  );
};
