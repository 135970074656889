import { Cookies } from "react-cookie-consent";

export type GAParams = {
  target?: string;
};

export type GAEvent = {
  type: string;
  params?: GAParams;
};

export const consentId = "user-consent-google-analytics";
export const GATrackingId = "G-BC8CB4HDXL";

export const checkConsent = () => {
  const consent = Cookies.get(consentId);
  if (consent === "true" && typeof window !== "undefined") {
    return true;
  }
  return false;
};

export const logEvent = ({ type, params }: GAEvent) => {
  if (checkConsent()) {
    window.gtag("event", type, params);
  }
};
