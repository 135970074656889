import styled from "@mui/system/styled";
import Box from "@mui/material/Box";
import { ReactNode } from "react";
import { themeOptions } from "../../Styles/theme";
import { Size } from "../../utils/types";

const getSize = (size: Size) => {
  switch (size) {
    case Size.SMALL:
      return "70vw";
    case Size.MID:
      return "80vw";
    case Size.FULL:
    default:
      return "100vw";
  }
};

const Container = styled(Box)<{ size: Size }>(({ theme, size }) => ({
  backgroundColor: themeOptions.palette?.background?.default,
  padding: theme.spacing(1),
  borderRadius: "4px",
  textAlign: "center",
  margin: "0 auto",
  maxWidth: getSize(size),
  [theme.breakpoints.down("sm")]: {
    maxWidth: size === Size.SMALL ? getSize(Size.FULL) : getSize(size),
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: getSize(Size.FULL),
  },
}));

export const LayoutContainer = ({
  children,
  size,
}: {
  children: ReactNode;
  size?: Size;
}) => {
  return <Container size={size || Size.FULL}>{children}</Container>;
};
