import { styled } from "@mui/material/styles";
import { ReactNode, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useIsMobile } from "../../helpers/isMobile";

const CarouselContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(1),
  textAlign: "center",
  position: "relative",
  maxWidth: "100%",
  width: "100%",
  overflow: "hidden",
}));

const ListContainer = styled("ul")(({ theme }) => ({
  listStyleType: "none",
  margin: "0 auto",
  display: "flex",
  flexFlow: "row nowrap",
  transition: "transform 0.5s ease-in-out",
  padding: theme.spacing(2),
}));

const ListItem = styled("li")<{ isMobile: boolean }>(({ isMobile }) => ({
  padding: "6px",
  minWidth: isMobile ? "50%" : "33.33%",
  maxWidth: "180px",
  alignSelf: "center",
  boxSizing: "border-box",
}));

const Arrow = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: "2rem",
  zIndex: 1,
  "&:first-of-type": {
    left: theme.spacing(2),
  },
  "&:last-of-type": {
    right: theme.spacing(2),
  },
}));

type CarouselProps = {
  items: ReactNode[];
};

export const Carousel = ({ items }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = items.length;
  const isMobile = useIsMobile();
  const itemsPerSlide = isMobile ? 2 : 3;

  const handlePrevClick = () => {
    if (currentIndex === 0) {
      setCurrentIndex(totalItems - itemsPerSlide);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex === totalItems - itemsPerSlide) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <CarouselContainer>
      <Arrow onClick={handlePrevClick}>
        <ArrowBackIosIcon />
      </Arrow>
      <ListContainer
        style={{
          margin: isMobile ? "0" : "0px 80px",
          transform: `translateX(-${currentIndex * (100 / itemsPerSlide)}%)`,
        }}
      >
        {items.map((item, index) => (
          <ListItem key={index} isMobile={isMobile}>
            {item}
          </ListItem>
        ))}
      </ListContainer>
      <Arrow onClick={handleNextClick}>
        <ArrowForwardIosIcon />
      </Arrow>
    </CarouselContainer>
  );
};
