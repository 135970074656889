import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const defaultTallyConfig = {
    formId: "",
    openOnLoad: false,
    buttonText: "contact me",
    popup: {
      emoji: {
        text: "👋",
        animation: "wave"
      }
    },
}

export const TallyPopup = ({ tallyConfig }) => {
  const customTallyConfig = useRef({ ...defaultTallyConfig, ...tallyConfig})
  const [isHiddenButton, setIsHiddenButon] = useState(false);

  useEffect(() => {
    var script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    window.TallyConfig = customTallyConfig.current
    if (customTallyConfig.current.openOnLoad && window.matchMedia("(min-width: 450px)").matches) {
      document.head.appendChild(script);
    }
  }, [customTallyConfig]);

  
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes?.length && mutation.addedNodes[0].classList?.contains("tally-popup")) {
          setIsHiddenButon(true)
        } 
        if (mutation.removedNodes?.length && mutation.removedNodes[0].classList?.contains("tally-popup")) {
          setIsHiddenButon(false)
        } 
      });
    });

    observer.observe(document.body, { subtree: true, childList: true });

    return () => {
      observer.disconnect();
    };
  }, []);
  
  return (
    <div> 
      <Button
        variant="contained" 
        startIcon={<EmailOutlinedIcon fontSize="small" />} 
        aria-label="contact" 
        size="small"
        sx={{ 
          bottom: "2.5vh",
          position: "fixed",
          right: "6vw",
          visibility: isHiddenButton ? "hidden" : "visible"
        }}
        data-tally-open={customTallyConfig.current.formId} 
        data-tally-emoji-text={customTallyConfig.current.popup.emoji} 
        data-tally-emoji-animation={customTallyConfig.current.popup.animation}
      > 
        {customTallyConfig.current.buttonText || defaultTallyConfig.buttonText}
      </Button>
    </div>
  );
};
