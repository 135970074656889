import { MouseEvent, useCallback, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery, { PhotoProps } from "react-photo-gallery";

export type ImageInGallery = {
  original: {
    url?: string;
    available: boolean;
    size: Array<number>;
    img_src: string;
  };
  prints: {
    url?: string;
    size: Array<number>[];
    img_src: string;
  };
  img_src: string;
  thumbnail_src: string;
  title: string;
  description: string;
};

const ImageGallery = ({
  images,
  size,
  margin,
}: {
  images: any[];
  size?: number;
  margin?: number;
}) => {
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = useCallback(
    (
      event: MouseEvent,
      { photo, index }: { photo: PhotoProps; index: number }
    ) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    },
    []
  );

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const photos = images.map(
    (item: { img_src: any; original: { size: any[] }; title: any }) => ({
      src: item.img_src,
      srcSet: item.img_src,
      width: item.original.size[0],
      height: item.original.size[1],
      alt: item.title,
    })
  );

  return (
    <div>
      <Gallery
        direction={"row"}
        photos={photos}
        onClick={openLightbox}
        margin={margin}
        targetRowHeight={size || 300}
      />
      {/* @ts-ignore */}
      <ModalGateway>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                source: x.src,
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  );
};

export default ImageGallery;
