import Grid from "@mui/system/Unstable_Grid";
import Navbar from "..//Navbar";
import Footer from "../Footer";
import { ReactNode } from "react";
import { Size } from "../../utils/types";
import { LayoutContainer } from "./LayoutContainer";

import { styled } from "@mui/material";

const CustomContainer = styled("div")(({ theme }) => ({
  overflowX: "hidden",
  overflowY: "auto",
  paddingTop: "24px",
}));

export const Layout = ({
  children,
  size,
}: {
  children: ReactNode;
  size?: Size;
}) => {
  return (
    <CustomContainer>
      <Grid container spacing={2}>
        <Navbar />
        <Grid xs={12}>
          <LayoutContainer size={size || Size.FULL}>{children}</LayoutContainer>
        </Grid>
        <Grid xs={12}>
          <Footer mini={true} />
        </Grid>
      </Grid>
    </CustomContainer>
  );
};
