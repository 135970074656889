import { createTheme, ThemeOptions } from "@material-ui/core";
import createPalette from "@material-ui/core/styles/createPalette";

export const palette = createPalette({
  primary: {
    main: "#1F1F1F",
    light: "#515151",
    dark: "#393939",
  },
  secondary: { main: "#BA324F" },
  error: { main: "#DC493A" },
  warning: { main: "#ab5b00" },
  info: { main: "#623CEA" },
  success: { main: "#426A5A" },
  type: "light",
  tonalOffset: 0.5,
  contrastThreshold: 1,
  text: {
    primary: "#1F1F1F",
    secondary: "#0000008a",
    disabled: "#b3b3b3",
    hint: "#BA324F",
  },
  divider: "#d3d3d3",
  action: {
    active: "#BA324F",
    hover: "#DC493A",
  },
  background: { default: "#F0EADE" },
});

export const themeOptions: ThemeOptions = {
  palette,
  shape: {
    borderRadius: 5,
  },
  typography: {
    body1: {
      fontFamily: "Inter",
      color: palette.primary.light,
      fontSize: "9pt",
      letterSpacing: "1pt",
    },
    body2: {
      fontFamily: "Inter",
      color: palette.primary.dark,
      fontSize: "8.5pt",
      fontWeight: 600,
      letterSpacing: "1.5pt",
    },
    allVariants: { fontFamily: "Inter" },
    caption: {
      fontFamily: "Inter",
      fontWeight: 200,
      fontSize: "8pt",
      letterSpacing: "1.5pt",
    },
    h1: {
      fontFamily: "Inter",
      color: palette.primary.dark,
      fontSize: "3rem",
      fontWeight: 800,
      margin: "12px",
      lineHeight: "normal",
      letterSpacing: "1.5pt",
    },
    h2: {
      fontFamily: "Inter",
      color: palette.primary.dark,
      fontSize: "2.5rem",
      margin: "12px",
      lineHeight: "normal",
      fontWeight: 600,
      letterSpacing: "1.5pt",
    },
    h3: {
      fontFamily: "Inter",
      color: palette.primary.dark,
      fontSize: "2rem",
      fontWeight: 600,
      letterSpacing: "1.5pt",
    },
    h4: {
      fontFamily: "Inter",
      color: palette.primary.dark,
      fontSize: "1.8rem",
      fontWeight: 400,
      letterSpacing: "1.5pt",
    },
    h5: {
      fontFamily: "Inter",
      color: palette.primary.dark,
      fontSize: "1.5rem",
      fontWeight: 400,
      letterSpacing: "1.5pt",
    },
  },
};

export const theme = createTheme(themeOptions);
