import { styled, Typography } from "@mui/material";
import { Size } from "../utils/types";
import { Layout } from "../Components/Layout";
import { ContactForm } from "../Components/ContactForm/ContactForm";
import { useTranslatedContent } from "../Components/LanguageSwitcher/hook";

const Container = styled("div")(() => ({
  minHeight: "50vh",
  display: "flex",
  justifyContent: "center",
  flexFlow: "row nowrap",
  maxWidth: "60vw",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    maxWidth: "90vw",
    flexFlow: "column-reverse wrap",
  },
}));

const ContactContainer = styled("div")(() => ({
  alignSelf: " center",
  maxWidth: "40vw",
  margin: "32px auto",
  "@media (max-width: 768px)": {
    margin: "0 auto",
    maxWidth: "90vw",
  },
}));

const Contact = () => {
  const { renderText } = useTranslatedContent();

  return (
    <Layout size={Size.SMALL}>
      <Typography variant="h1">
        {renderText({ key: "pages.contact.title" })}
      </Typography>
      <Container>
        <ContactContainer>
          <Typography variant="body1">
            {renderText({
              key: "pages.contact.description",
              url: "mailto:collagequeendom@gmail.com",
            })}
          </Typography>
          <br />
          <ContactForm />
          <br />
        </ContactContainer>
        <br />
      </Container>
    </Layout>
  );
};

export default Contact;
