import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { theme } from "./Styles/theme";
import {
  About,
  Home,
  Prints,
  Portfolio,
  ColorRoulette,
  Exhibitions,
  NotFound,
  ColorRouletteSubmission,
  Landing,
  GreenIssue,
  Cookies,
  Contact,
  YellowIssue,
  OrangeIssue,
} from "./Pages";
import { ConsentBanner } from "./Components/ConsentBanner/";
import { Analytics } from "./Components/Analytics";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ConsentBanner />
        <Analytics />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Prints />} />
          <Route path="/about" element={<About />} />
          <Route path="/zines" element={<ColorRoulette />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route
            path="/zines/submission"
            element={<ColorRouletteSubmission />}
          />
          <Route path="/zines/greenissue" element={<GreenIssue />} />
          <Route path="/zines/yellowissue" element={<YellowIssue />} />
          <Route path="/zines/orangeissue" element={<OrangeIssue />} />
          <Route path="/exhibitions" element={<Exhibitions />} />
          <Route path="/privacypolicy" element={<Cookies />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
