import { logEvent } from "../helpers/googleAnalytics";

export const pageClick = (page: string) => {
  const GAData = {
    type: "pageClick",
    params: { target: page },
  };

  logEvent(GAData);
};

export const instaLinkClicked = () => {
  const GAData = { type: "instaClicked" };
  logEvent(GAData);
};

export const caraLinkClicked = () => {
  const GAData = { type: "caraClicked" };
  logEvent(GAData);
};

export const pageVisit = (page: string) => {
  const GAData = {
    type: "pageVisit",
    params: { target: page },
  };

  logEvent(GAData);
};
