import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubmenuItemStyle = {
  marginLeft: "26px",
  borderLeft: "2px solid black",
  ":hover": {
    color: "white",
  },
};

export type SubmenuItem = {
  title: string;
  to: string;
};

export const SubMenuItem = ({ item }: { item: SubmenuItem }) => {
  const navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(path);
  };

  return (
    <MenuItem onClick={() => routeChange(item.to)} sx={SubmenuItemStyle}>
      {item.title}
    </MenuItem>
  );
};
