export type LinkItem = {
  title: string;
  to: string;
  target?: string;
  subMenuItems?: LinkItem[];
};

export const links = [
  {
    title: "Home",
    to: "/",
    target: "_self",
  },
  {
    title: "Art",
    to: "/portfolio",
    target: "_self",
  },
  {
    title: "Zines",
    to: "/zines",
    target: "_self",
    subMenuItems: [
      { title: "Color Roulette", to: "/zines" },
      { title: "Green Issue", to: "/zines/greenissue" },
      { title: "Yellow Issue", to: "/zines/yellowissue" },
      { title: "Orange Issue", to: "/zines/orangeissue" },
    ],
  },
  {
    title: "Purchase",
    to: "/shop",
    target: "_self",
  },
  {
    title: "Exhibitions",
    to: "/exhibitions",
    target: "_self",
  },
  {
    title: "About",
    to: "/about",
    target: "_self",
  },
  {
    title: "Contact",
    to: "/contact",
    target: "_self",
  },
];
